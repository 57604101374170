import React from "react";
import theme from "theme";
import { Theme, Link, Text, Input, Button, Box, Section, Image, Span, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";

const BASEURL = "http://localhost:8000/"

export default (() => {
	const [text, setText] = React.useState("");
	const [response, setResponse] = React.useState("Your response will appear here");
	const [submitDisabled, setSubmitDisabled] = React.useState(false);
	const [uploadDisabled, setUploadDisabled] = React.useState(false);
	const [files, setFiles] = React.useState([]);

	const handleDrop = React.useCallback(
		(event) => {
		  event.preventDefault();
			// Concat the new files to the existing files in state so that the user can upload multiple files
			const prevFilesUploadedCount = files.length;
		  setFiles([...files, ...Array.from(event.dataTransfer.files)]);
		alert(
			`You've dropped ${prevFilesUploadedCount + (event.dataTransfer.files.length)} files for upload!`	
		)
		},
		[setFiles]
	  );
	
	  const handleUpload = React.useCallback(async () => {
		if (files.length === 0) {
			alert('oops! you forgot to upload your document!')
			return;			
		}
		setUploadDisabled(true);
		const formData = new FormData();
		files.forEach((file) => formData.append('files', file));
		try {
			const response = await fetch(BASEURL + 'upload', {
				method: 'POST',
				body: formData,
			});
			if (!response.ok) {
				throw new Error('Failed to upload files');
			}
		} catch (error) {
			console.log(error);
		}
		setUploadDisabled(false);
	  }, [files]);

	const FileDrop = () => {
	
		return (
		  <div
			onDrop={handleDrop}
			onDragOver={(event) => event.preventDefault()}
		  >
			<Image
					max-width="100%"
					min-height="632px"
					object-fit="cover"
					sm-min-height="16px"
					src={"https://images.unsplash.com/photo-1481627834876-b7833e8f5570?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1228&q=80"}
				/>
		  </div>
		);
	  };

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!text || text.trim().length === 0) {
			alert('oops! you forgot to type your question!')
			return;
		}
		setSubmitDisabled(true);
		try {
		  const res = await fetch(BASEURL + 'query', {
			method: "POST",
			body: text,
		  });
		  const response = await res.json();
		  setResponse(response.message.response);
		} catch (error) {
		  console.error(error);
		}
		setSubmitDisabled(false);
	  };

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Talk to your documents like a human
			</title>
			<meta name={"description"} content={"Manage all your events in a single app. Our online event management platform provides various services event planners and project managers may need."} />
			<meta property={"og:title"} content={"Business Matchmaking Has Never Been Easier"} />
			<meta property={"og:description"} content={"Manage all your events in a single app. Our online event management platform provides various services event planners and project managers may need."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/OG-image.png?v=2021-09-22T11:33:13.239Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/favicon%2032x32.png?v=2021-09-22T11:33:41.498Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/270x270.png?v=2021-09-22T11:33:55.934Z"} />
		</Helmet>
		<Section padding="38px 0 38px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" display="flex" />
			<Link
				text-decoration-line="initial"
				hover-opacity=".8"
				width="20%"
				md-width="40%"
				sm-width="50%"
				href="/"
				display="flex"
			>
				<Text margin="0px 0px 0px 0px" font="--lead" color="--dark">
					askPoli
				</Text>
			</Link>
			<Components.QuarklycommunityKitMobileSidePanel md-justify-content="flex-end">
				<Override slot="Children" display="flex" />
				<Override slot="Content" padding="0px 0px 0px 0px" md-margin="38px 0px 0px 0px" />
				<Override
					slot="Button Text"
					md-font="--lead"
					md-text-transform="uppercase"
					sm-margin="3px 6.3px 0px 0px"
					md-margin="1px 6.3px 0px 0px"
				/>
				<Box
					justify-content="flex-end"
					md-align-items="center"
					md-flex-direction="column"
					md-justify-content="center"
					md-padding="20px 0px 20px 0px"
					md-margin="0px 0px 0px 0px"
					display="flex"
				>
					<Button>
						Login or Sign up
					</Button>
				</Box>
			</Components.QuarklycommunityKitMobileSidePanel>
		</Section>
		<Section background="--color-darkL1" padding="0px 0 0px 0" quarkly-title="HeroBlock" md-flex-wrap="wrap">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				padding="80px 0px 40px 0px"
				md-padding="40px 0px 40px 0px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				lg-padding="80px 32px 40px 0px"
				md-width="100%"
			>
				<Box
					lg-width="100%"
					display="flex"
					flex-direction="column"
					width="70%"
					justify-content="space-between"
					flex="1 1 0%"
				>
					<Text font="--headline3" color="--light" sm-font="400 36px/1.2 &quot;Fira Sans&quot;, sans-serif" margin="0px 0px 0px 0px">
						Anyone can read documents
					</Text>
					<Text font="--headline3" color="--light" sm-font="400 36px/1.2 &quot;Fira Sans&quot;, sans-serif" margin="0px 0px 0px 0px">
						Only YOU can talk to them
					</Text>
					<Text margin="32px 0px 0px 0px" font="--lead" color="--light" md-padding="0px 0px 40px 0px">
						Save time by chatting with your knowledge base instead of searching for answers. Scroll down to get started.
					</Text>
				</Box>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
			>
				<Image
					src="https://images.unsplash.com/photo-1555421689-3f034debb7a6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
					max-width="100%"
					min-height="600px"
					object-fit="cover"
					lg-min-height="500px"
					sm-min-height="400px"
				/>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				width="70%"
				align-items="center"
				border-style="solid"
				padding="40px 0px 40px 0px"
				md-padding="60px 0px 60px 0px"
				empty-border-width="1px"
				border-color="#C4C4C4"
				empty-border-style="solid"
				empty-border-color="LightGray"
				border-width="1px 0px 0px 0px"
				empty-min-height="64px"
				display="flex"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" font="--headline2" color="--dark" lg-font="400 30px/1.2 &quot;Fira Sans&quot;, sans-serif">
					<Span
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						lg-font="700 30px/48px &quot;Fira Sans&quot;, sans-serif"
						color="--primary"
						word-break="normal"
						white-space="normal"
						font="700 40px/48px &quot;Fira Sans&quot;, sans-serif"
						overflow-wrap="normal"
						user-select="auto"
						pointer-events="auto"
					>
						drag and drop your files below
					</Span>
				</Text>
				<Button display="flex" margin="15% 0px 0px 0px" justify-content="flex-end" background="#0bc104" disabled={uploadDisabled} onClick={handleUpload}>
					<Strong>
						Upload
					</Strong>
				</Button>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0">
			<Override slot="SectionContent" md-flex-wrap="wrap" flex-direction="row" />
			<Box
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
			>
				<FileDrop />
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="50%"
				empty-min-width="64px"
				empty-border-style="solid"
				display="flex"
				flex-direction="column"
				md-width="100%"
				empty-min-height="64px"
			>
				<Box
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					padding="32px 32px 32px 32px"
					height="50%"
					empty-min-width="64px"
					empty-min-height="64px"
				>
					<Text
						width="60%"
						lg-width="100%"
						lg-font="normal 400 24px/1.3 &quot;Fira Sans&quot;, sans-serif"
						margin="0px 0px 16px 0px"
						font="--headline4"
						color="--dark"
					>
						Ask away!
					</Text>
					<Input
						display="block"
						placeholder-color="White"
						width="100%"
						height="60%"
						color="white"
						background="--color-primary"
						word-break="normal"
						max-length="100"
						white-space="normal"
						overflow-wrap="normal"
						text-overflow="clip"
						placeholder="Type your question here..."
						onChange={(e) => setText(e.target.value)}
					/>
						
					<Button display="block" margin="20px 0px 0px 80%" background="#0bc104" onClick={handleSubmit} disabled={submitDisabled}>
						<Strong>
							Submit
						</Strong>
					</Button>
				</Box>
				<Box
					empty-border-style="solid"
					empty-border-color="LightGray"
					padding="32px 32px 32px 32px"
					background="--color-lightD1"
					height="50%"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline4"
						color="--dark"
						width="80%"
						lg-width="100%"
						lg-font="normal 400 24px/1.3 &quot;Fira Sans&quot;, sans-serif"
					>
						Answering box
					</Text>
					<Text
						margin="0px 0px 65px 0px"
						font="--base"
						color="--dark"
						width="90%"
						lg-width="100%"
						height="90%"
						max-width="fit-content"
						max-height="fit-content"
					>
						{response}
					</Text>
				</Box>
			</Box>
		</Section>
		<Box min-width="100px" min-height="100px" />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6143a05129a0a1001e6c835a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n* {\n    scroll-behavior: smooth;\n    }"}
			</style>
		</RawHtml>
	</Theme>;
});