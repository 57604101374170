export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"63e057baf0019100228f0e0f",
				"63e057baf0019100228f0e13"
			]
		},
		"63e057baf0019100228f0e0f": {
			"id": "63e057baf0019100228f0e0f",
			"name": "404",
			"pageUrl": "404"
		},
		"63e057baf0019100228f0e13": {
			"id": "63e057baf0019100228f0e13",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"overflow-x": "hidden",
			"overflow-y": ""
		},
		"seo": {
			"title": "Talk to your documents like a human",
			"description": "Talk to your documents like a human. Our online platform lets you ask questions and get answers from your documents in natural language.",
			"og:image": "https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/OG-image.png?v=2021-09-22T11:33:13.239Z",
			"og:title": "Talk to your documents like a human",
			"og:description": "Talk to your documents like a human. Our online platform lets you ask questions and get answers from your documents in natural language.",
			"favicon_32px": "https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/favicon%2032x32.png?v=2021-09-22T11:33:41.498Z",
			"favicon_apple_152px": "https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/270x270.png?v=2021-09-22T11:33:55.934Z"
		}
	}
}